/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary: #868e96; 
$color-success: #1dc9b7; 
$color-info: #2196F3; 
$color-warning: #ffc241; 
$color-danger: #b57d6a;

/* #GLOBAL IMPORTS
========================================================================== */
@import './src/scss/_imports/_theme-modules-import';

/* #Reset userselect
========================================================================== */
#myapp-3 {
	box-shadow: none !important;
}
#myapp-7 {
	box-shadow: 0 0 0 3px #000000;
}